<template>
    <div>
        <!-- <v-text-field
            class="amount_input"
            placeholder="Dense & Rounded"
            outlined
            v-model="amount"
            v-number="number"
        ></v-text-field> -->
        <input
            type="text"
            class="amount_input"
            v-model="raw"
            v-number="number"
            @change="change"
            @keydown.up="up"
            @keydown.down="down"
            contenteditable="contenteditable"
        />
    </div>
</template>

<script>
import Big from 'big.js'
import { BN } from '@lamina1/lamina1-js'
import { number } from '@coders-tm/vue-number-format'

export default {
    directives: {
        number: number,
    },
    data() {
        return {
            raw: '0',
            value: 0,
            number: {
                decimal: '.',
                separator: '',
                prefix: '',
                suffix: '',
                precision: 18,
                masked: false,
                reverseFill: false,
                minimumFractionDigits: '18',
                prefill: true,
            },
        }
    },
    created() {
        this.value = new Big(0)
    },
    props: {
        denomination: {
            type: Number,
            default: 0,
        },
        max: {
            type: [BN, null],
        },
        min: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        stepSize() {
            return Math.pow(10, -this.denomination)
        },
        bigMax() {
            if (this.max) {
                // this.max is a BN in satoshis
                let satoshi = Big(this.max)
                let divider = Big(10).pow(this.denomination)
                return satoshi.div(divider)
            }
            return null
        },
        bigMin() {
            if (typeof this.min != 'undefined') {
                return Big(this.min)
            }
            return null
        },
    },
    mounted() {
        this.cleanInput()
    },
    watch: {
        denomination() {
            this.cleanInput()
        },
    },
    methods: {
        // Emit in BN as satoshis!
        emit() {
            // console.log(this.value.toString());
            let tens = Big(10).pow(this.denomination)
            let satoshis = this.value.times(tens)
            let bn = new BN(satoshis.toFixed(0))
            this.$emit('change', bn)
        },
        change(ev) {
            this.cleanInput()
        },
        input(ev) {
            ev.preventDefault()
            let data = ev.data

            if (data !== null) {
                let num = parseInt(data)
                if (isNaN(num)) {
                    this.cleanInput()
                }
            }
        },
        cleanInput() {
            let rawnum
            try {
                if (this.raw === '') this.raw = 0
                rawnum = new Big(this.raw)
            } catch (err) {
                rawnum = this.value
            }

            if (this.bigMax != null) {
                if (rawnum.gt(this.bigMax)) {
                    rawnum = this.bigMax
                } else if (rawnum.lt(this.bigMin)) {
                    rawnum = this.bigMin
                }
            }
            this.value = rawnum
            this.raw = rawnum.toFixed(this.denomination)
            this.emit()
        },
        clear() {
            this.cleanInput()
        },
        maxout() {
            if (this.bigMax) {
                this.value = this.bigMax
                this.valueToRaw()
            }
        },
        valueToRaw() {
            let val = this.value
            if (this.bigMax != null) {
                if (val.gt(this.bigMax)) {
                    val = this.bigMax
                    this.value = val
                } else if (val.lt(this.bigMin)) {
                    val = this.bigMin
                    this.value = val
                }
            }
            this.raw = val.toFixed(this.denomination)
            this.emit()
        },
        down(ev) {
            this.value = this.value.minus(this.stepSize)
            this.valueToRaw()
        },
    },
}
</script>
<style scoped>
.amount_input {
    width: 100%;
    border: 0px;
    background: transparent;
    color: var(--primary-color);
    margin-top: 8px;
    margin-left: 11px;
}
</style>