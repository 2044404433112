












import { Vue, Component, Prop } from 'vue-property-decorator'
import Erc20Token from '@/js/Erc20Token'
import { WalletType } from '@/js/wallets/types'

import { bnToBig } from '@/helpers/helper'
import Big from 'big.js'
import EVMTokenSelectModal from '@/components/modals/EvmTokenSelect/EVMTokenSelectModal.vue'
import { iErc721SelectInput } from '@/components/misc/EVMInputDropdown/types'
import ERC721Token from '@/js/ERC721Token'
@Component({
    components: { EVMTokenSelectModal },
})
export default class EVMAssetDropdown extends Vue {
    isPopup = false
    selected: Erc20Token | ERC721Token | 'native' = 'native'

    @Prop({ default: false }) disabled!: boolean

    $refs!: {
        select_modal: EVMTokenSelectModal
    }

    get symbol() {
        if (this.selected === 'native') return 'L1'
        else return this.selected.data.symbol
    }

    showPopup() {
        this.$refs.select_modal.open()
    }

    get avaxBalance(): Big {
        let w: WalletType | null = this.$store.state.activeWallet
        if (!w) return Big(0)
        let balBN = w.ethBalance
        return bnToBig(balBN, 18)
    }

    select(token: Erc20Token | 'native') {
        this.selected = token
        this.$emit('change', token)
    }

    clear() {
        this.select('native')
    }

    selectERC721(val: iErc721SelectInput) {
        this.selected = val.token
        this.$emit('changeCollectible', val)
    }
}
