




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { AvaNftFamily } from '@/js/AvaNftFamily'
import { IWalletNftDict } from '@/store/types'
import { NFTTransferOutput, UTXO } from '@lamina1/lamina1-js/dist/apis/avm'
import { Buffer } from '@lamina1/lamina1-js'
import { PayloadBase, PayloadTypes } from '@lamina1/lamina1-js/dist/utils'
import NftPayloadView from '@/components/misc/NftPayloadView/NftPayloadView.vue'
import { getPayloadFromUTXO } from '@/helpers/helper'

let payloadtypes = PayloadTypes.getInstance()

@Component({
    components: {
        NftPayloadView,
    },
})
export default class CollectibleFamily extends Vue {
    @Prop() family!: AvaNftFamily
    @Prop({ default: [] }) disabledIds!: string[]

    get nftFamilies() {
        return this.$store.getters['Assets/nftFamilies']
    }

    get nftDict(): IWalletNftDict {
        // return this.$store.getters.walletNftDict
        return this.$store.getters['Assets/walletNftDict']
    }
    get utxos() {
        let id = this.family.id
        return this.nftDict[id] || []
    }

    get uniqueGroups() {
        let ids: number[] = []
        return this.utxos.filter((utxo) => {
            let gId = (utxo.getOutput() as NFTTransferOutput).getGroupID()
            if (ids.includes(gId)) {
                return false
            } else {
                ids.push(gId)
                return true
            }
        })
    }

    get payloads() {
        return this.uniqueGroups.map((utxo) => {
            return getPayloadFromUTXO(utxo)
        })
    }

    click(utxo: UTXO) {
        this.$emit('select', utxo)
    }
}
