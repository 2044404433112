
































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AvaAsset from '@/js/AvaAsset'
import BalanceRow from './BalanceRow.vue'
import CollectibleTab from './CollectibleTab.vue'
import { UTXO } from '@lamina1/lamina1-js/dist/apis/avm'

@Component({
    components: {
        BalanceRow,
        CollectibleTab,
    },
})
export default class BalancePopup extends Vue {
    @Prop() assets!: AvaAsset[]
    @Prop({ default: false }) isNft?: boolean
    @Prop({ default: () => [] }) disabledIds!: string[] // asset id | if nft the utxo id

    isActive: boolean = false

    select(asset: AvaAsset) {
        if (asset.amount.isZero()) return
        if (this.isDisabled(asset)) return

        this.$emit('select', asset)
    }

    selectNFT(utxo: UTXO) {
        this.$emit('select', utxo)
        this.closePopup()
    }

    isDisabled(asset: AvaAsset): boolean {
        if (this.disabledIds.includes(asset.id)) return true
        return false
    }

    closePopup() {
        this.isActive = false
        this.$emit('close')
    }
}
