




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { JSONPayload } from '@lamina1/lamina1-js/dist/utils'
import { IGenericNft } from '@/components/wallet/studio/mint/types'

@Component
export default class UtfPayloadView extends Vue {
    $refs!: {
        image: HTMLImageElement
        video: HTMLVideoElement
    }
    @Prop() payload!: JSONPayload

    isVideo = false
    isImage = false
    isAudio = false

    isError = false
    jsonData: IGenericNft | null = null
    isHover = false

    get content(): string {
        return this.payload.getContent().toString()
    }

    get desc() {
        return this.jsonData?.desc
    }

    get img() {
        return this.jsonData?.img
    }

    get title() {
        return this.jsonData?.title
    }

    onVideoMeta(ev: any) {
        this.isVideo = true
    }

    mounted() {
        try {
            this.jsonData = JSON.parse(this.content).avalanche
        } catch (e) {
            this.isError = true
        }
    }

    @Watch('payload')
    onPayloadChange(val: JSONPayload) {
        try {
            this.jsonData = JSON.parse(this.content).avalanche
        } catch (e) {
            this.isError = true
        }
    }
}
