






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { JSONPayload } from '@lamina1/lamina1-js/dist/utils'

import GenericPayloadView from '@/components/misc/NftPayloadView/views/GenericPayloadView.vue'

@Component({
    components: {
        GenericPayloadView,
    },
})
export default class JsonPayloadView extends Vue {
    @Prop() payload!: JSONPayload
    val = ''

    updateText() {
        this.val = this.jsonText
    }

    get jsonText() {
        let data = this.text
        try {
            let obj = JSON.parse(data)
            return JSON.stringify(obj, undefined, 4)
        } catch (e) {
            return data
        }
    }
    get text(): string {
        return this.payload.getContent().toString()
    }

    get isGeneric() {
        let data = this.text
        try {
            let obj = JSON.parse(data)

            if (obj.hasOwnProperty('avalanche')) {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
        return false
    }

    @Watch('payload')
    onPayloadChange() {
        this.updateText()
    }

    mounted() {
        this.updateText()
    }
}
